<template>
  <b-container fluid>
    <main-modal id="activationDetalilsModal" size="lg">
      <template v-slot:header>
        <h4 class="font-weight-bold">Business Request</h4>
      </template>
      <template v-slot:body>
        <activation-details :activationDetails="activationDetails" @activation-provider="activate"
                            :loadingActivation="loadingActivation"/>
      </template>
    </main-modal>
    <b-row>
      <b-col lg="12" class="mb-2">
        <h3>Activation</h3>
      </b-col>
      <b-col lg="12" class="mb-2">
        <iq-card class="filter-card">
          <b-row>
            <b-col md="3">
              <span>Filter by name:</span>
              <b-form-input v-model="filter.name" @keyup="reloadTable=true"
                            placeholder="Search">
              </b-form-input>
            </b-col>
            <b-col md="3">
              <span>Filter by type:</span>
              <main-select v-model="filter.profile_type" @change="reloadTable=true"
                           :options="typeFilterOptions"
                           label="key"
                           :reduce="data => data.value"
                           placeholder="--Select--">
              </main-select>
            </b-col>
<!--            <b-col md="3">
              <span>Filter by status:</span>
              <main-select v-model="filter.status" @change="reloadTable=true"
                           :options="statusFilterOptions"
                           label="key"
                           :reduce="data => data.value"
                           placeholder="&#45;&#45;Select&#45;&#45;">
              </main-select>
            </b-col>-->
          </b-row>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <main-table
            :fields="columns"
            class="mb-0 table-borderless"
            @sortChanged="sortChanged"
            :list_url="'activations'"
            :reloadData="reloadTable"
            :custom-filter="filter"
        >
        </main-table>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { core } from '@/config/pluginInit'
import activationsServices from '../services/activations.services'
import activationDetails from '../components/activationDetails'
export default {
  components: {
    activationDetails
  },
  data () {
    return {
      columns: [
        '#',
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Profile Type', key: 'profile_type', class: 'text-left' },
        { label: 'Status', key: 'status', class: 'text-left' },
        { label: 'Password', key: 'password_text', class: 'text-left' },
        {
          label: 'Actions',
          key: 'actions',
          class: 'text-left',
          type: 'actions',
          actions: [{
            icon: 'las la-eye',
            color: 'success-light',
            text: 'View',
            actionName: 'viewProfile',
            showIf: () => this.hasPer('activation.view'),
            actionParams: ['id']
          }
          ]
        }
      ],
      activationDetails: {},
      providerId: '',
      reloadTable: false,
      loadingActivation: false,
      filter: { name: '', status: '', profile_type: '', sort_type: 'desc' },
      typeFilterOptions: [
        { key: 'Sky', value: 'sky_' },
        { key: 'Sea', value: 'sea_' },
        { key: 'Earth', value: 'earth_' },
        { key: 'Energy', value: 'energy_' },
        { key: 'None', value: '' }
      ]
      /* statusFilterOptions: [
        { key: 'Pending acceptance', value: 'pending acceptance' },
        { key: 'Accepted', value: 'accepted' },
        { key: 'Rejected', value: 'rejected' },
        { key: 'None', value: '' }
      ] */
    }
  },
  methods: {
    sortChanged (key) {
      this.reloadTable = false
      this.filter.sort_type = key.sortDesc ? 'desc' : 'asc'
      this.reloadTable = true
    },
    viewProfile (obj) {
      activationsServices.getActivationDetails(obj.id).then(res => {
        this.providerId = obj.id
        this.activationDetails = res.data.data
        this.$bvModal.show('activationDetalilsModal')
      })
    },
    activate (obj) {
      this.loadingActivation = true
      activationsServices.activateProvider(this.providerId, obj).then(res => {
        core.showSnackbar('success', res.data.message)
        this.reloadTable = true
        this.$bvModal.hide('activationDetalilsModal')
      }).finally(() => {
        this.loadingActivation = false
      })
    }
  },
  mounted () {
    core.index()
  },
  beforeDestroy () {
    this.$root.$off('viewProfile', this.viewProfile)
  },
  created () {
    this.$root.$on('viewProfile', this.viewProfile)
  }
}
</script>
